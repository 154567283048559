import React from 'react';
import { ComboSt } from './styles';
import Buy from '../../components/Buy';

export default function Combo() {
  return (
    <ComboSt id="combo">
      <Buy unid="5" price="49" cupom="10" link="https://martinscomprasustentavel.carrinho.app/one-checkout/ocmtb/13603057" />
      <Buy unid="10" price="99" cupom="30" link="https://martinscomprasustentavel.carrinho.app/one-checkout/ocmtb/13603718" />
      <Buy unid="15" price="149" cupom="50" link="https://martinscomprasustentavel.carrinho.app/one-checkout/ocmtb/13603804" />

    </ComboSt>
  );
}
