import styled from 'styled-components';

export const BannerSt = styled.section`
  position: relative;
  padding: 20px 0;
  text-align: center;
  /* overflow: hidden; */
  
  .top{
    width: 100%;
    max-width: 260px;
    margin: 10px 0 20px 0;
  }

  .img_bag{
    width: 100vw;
    max-width: 600px;
    
    @media screen and (max-width: 800px){
      position: relative;
      left: calc(-5% - 10px);
      top: 20px; 
    }
  }
`;

export const BannerText = styled.div`

  margin: 20px 0 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
`;
