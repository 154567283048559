import React from 'react';
import { TopSt } from './styles';

export default function Top() {
  return (
    <TopSt>
      <h2>#Eupossomudaromundo</h2>
    </TopSt>
  );
}
