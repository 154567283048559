import React from 'react';
import * as styles from './styles';
import img from '../../assets/banner.png';
import Button from '../../components/Button';
import img2 from '../../assets/top.png';

export default function Banner() {
  return (
    <styles.BannerSt>

      <img src={img2} alt="top" className="top" />

      <Button text="Compre aqui sua ecobag e concorra a 02 iphones 14" green={false} />

      <img src={img} alt="banner" className="img_bag" />

      <styles.BannerText>

        <p className="section_p">
          <b>Chegou a hora de você</b>
          {' '}
          ajudar a mudar o mundo, e ainda concorrer a 02 IPhones 14. Adquira agora uma ecobag,
          {' '}
          <b>além de concorrer a 02 IPhones 14, você irá estar ajudando o planeta e também contribuindo com 02 instituições de caridade, são mais de R$50.000,00</b>
          {' '}
          que serão revertidos às mesmas
        </p>

      </styles.BannerText>
    </styles.BannerSt>
  );
}
