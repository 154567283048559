import styled from 'styled-components';

export const PartnershipSt = styled.section`
  width: 100%;
  padding: 20px 0 30px 0;
  
  > .center{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  img{
    width: 100%;
    max-width: 300px;
  }
`;
