import styled from 'styled-components';

export const FooterSt = styled.footer`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: ${(props) => props.theme.orange};
  color: ${(props) => props.theme.textLigth};

  padding-bottom: 10px;

  span{
    font-size: 16px;
    font-weight: 300;
    text-align: center;
  }
`;

export const FooterText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  h3{
    font-size: 80px;
  }

  h4{
    position: relative;
    top: 10px;
    font-size: 18px;
    text-transform: uppercase;
  }

  h2{
    font-size: 45px;
    text-transform: uppercase;
  }
`;
