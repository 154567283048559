import styled from 'styled-components';

export const InfoSt = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;

  img{
    width: 100%;
    max-width: 300px;
  }

  p{
    margin: 80px 0 10px 0;
    
    @media screen and (max-width: 800px){
      margin: 10px 0 10px 0;
      
    }
  }
`;

export const InfoText = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h3{
    text-transform: uppercase;
    text-align: center;
    margin-top: 10px;
    font-size: 18px;

    b{
      font-weight: 900;
      margin-left: 5px;
      color: ${(props) => props.theme.green};
    }
  }

  h4{
    text-transform: uppercase;
    text-align: center;
    margin-top: 5px;
    font-size: 18px;
    font-weight: 300;

    b{
      font-weight: 900;
      margin-left: 5px;
      color: ${(props) => props.theme.green};
    }
  }

  span{
    margin-top: 30px;
    font-size: 16px;
    font-weight: 300;
    text-align: center;
  }

  img{
    max-width: 350px;
    margin: 15px 0;
  }
  
`;

export const InfoImg = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: auto;

  img{
    width: 40%;
  }
`;
