import styled from 'styled-components';

export const TopSt = styled.header`
  display: flex;
  justify-content: space-around;
  width: 100%;
  padding: 5px 0;
  background-color: ${(props) => props.theme.orange};

  h2{
    font-size: 18px;
    letter-spacing: 0.05rem;
    text-transform: uppercase;
  }
`;
