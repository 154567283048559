import styled from 'styled-components';

export const ComboSt = styled.section`
  padding: 15px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

`;
