import React from 'react';
import { FooterSt, FooterText } from './styles';

export default function Footer() {
  return (
    <FooterSt>
      <FooterText>
        <h3>#</h3>
        <div>
          <h4>Eupossomudaro</h4>
          <h2>Mundo</h2>
        </div>
      </FooterText>
      <span>Todos os direitos reservados para Martins Supermercados &copy;</span>
    </FooterSt>
  );
}
