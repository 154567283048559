import { createGlobalStyle } from 'styled-components';
import img from './assets/background.jpg';

export const GlobalStyles = createGlobalStyle`
  *{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }

  html,body,#root{
    font-family: "Sora", sans-serif;
    scroll-behavior: smooth;
    color: ${(props) => props.theme.textLigth};
    background-image: url(${img});
    background-size: cover;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  a{
    text-decoration: none;
    color: inherit;
  }

  .center{
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1000px;
    padding: 0 5%;
    margin: 0 auto;
  }

  .section_title{
    font-size: 22px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 800;
    letter-spacing: 1px;
    color: ${(props) => props.theme.greenDark};
  }

  .section_p{
    font-size: 19px;
    margin-top: 10px;
    font-weight: 300;
    text-align: center;
  }
`;
