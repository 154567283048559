import styled from 'styled-components';

export const BuySt = styled.section`
  width: 100%;
  padding: 45px 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  background-color: ${(props) => props.theme.greenDark};

  &:nth-child(2n + 1){
    background-color: ${(props) => props.theme.orange};

    a{
      background-color: ${(props) => props.theme.greenDark};
    }
  }

  h2{
    font-size: 32px;
    letter-spacing: 1px;
    text-align: center;
    font-weight: 800;
    text-transform: uppercase;
  }
`;

export const BuyPrice = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 10px;

  h4{
    font-size: 26px;
    position: relative;
    top: 15px;
  }

  h3{
    font-size: 90px;
  }
`;

export const BuyText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h4{
    font-size: 32px;
    text-transform: uppercase;
    font-weight: 300;
    color: ${(props) => props.theme.background};
  }

  p{
    font-size: 26px;
    text-transform: uppercase;
    font-weight: 300;
  }


`;

export const BuyButton = styled.a`
  margin-top: 25px;
  font-size: 30px;
  text-transform: uppercase;
  font-weight: 800;
  width: 100%;
  text-align: center;
  padding: 15px 0;
  border-radius: 0.5rem;
  background-color: ${(props) => props.theme.orange};

`;
