import React from 'react';
import * as styles from './styles';
import Button from '../../components/Button';
import iphone from '../../assets/iphone.png';
import bag from '../../assets/bag.png';

export default function Info() {
  return (
    <styles.InfoSt>
      <img src={iphone} alt="iphone" />

      <p className="section_p">
        <b>
          Além desse super prêmio você vai ajudar o
          Lar dos Velhinhos e o CTA.
        </b>
      </p>

      <styles.InfoText>
        <Button text="Compre aqui sua ecobag" />

        <h3>
          Aproveite é apenas
          <b>R$10,00</b>
        </h3>
        <h4>
          <b>R$ 5,00</b>
          {' '}
          vai para a instituição
        </h4>

        <span>Compra 100% segura! Receba seu produto ou tenha seu dinheiro de volta.</span>

        <img src={bag} alt="iphone" />
      </styles.InfoText>

    </styles.InfoSt>
  );
}
