import React from 'react';
import * as styles from './styles';
import img from '../../assets/banner.png';
import ajude2 from '../../assets/ajude2.png';
import ajude1 from '../../assets/ajude1.png';
import lar from '../../assets/lar_foto.png';
import cta from '../../assets/cta_foto.png';
import iphone from '../../assets/iphone.png';
import Button from '../../components/Button';

export default function Icons() {
  return (
    <styles.IconsSt>
      <styles.IconsTop>
        <div className="center">
          <h3>Conheça um pouco do lar dos velhinhos de Rio Azul - Pr e o CTA de rebouças - pr</h3>
        </div>
      </styles.IconsTop>

      <div className="center">

        <styles.IconsGroup>
          <img src={ajude2} alt="lar dos velhinos" />

          <h2 className="section_title">Lar dos velhinhos</h2>

          <p className="section_p">
            Lar dos Velhinhos de Rio Azul é uma instituição de longa permanência para idosos, entidade privada sem ﬁns lucrativos, localizada em Rio Azul cidade do inteiror do Paraná, hoje o Lar tem capacidade para atender até 40 idosos, que são oriundos de algumas cidades da região. Esses idosos residentes, chegam na entidade fragilizados, física ou moralmente, muitos vítimas de maus tratos e abandono por parte dos familiares, e no Lar dos Velhinhos passam a ter uma nova chance de um envelhecer com dignidade. A entidade hoje possui uma equipe multidisciplinar de aproximadamente 20 colaboradores que trabalham 24 horas por dia, visando dar a assistência necessária a essas pessoas que por muitas vezes já tem sua mobilidade diﬁcultada, sanidade mental debilitada, devido a idade. O principal desaﬁo é a manutenção de custos da estrutura e possíveis melhorias, tento em vista que a entidade usa parte da aposentadoria dos idosos como prevê o estatuto do idoso, e alguns convênios com governos municipais. Porém por muitas vezes essas receitas não são suﬁcientes para prover o básico necessário para uma eﬁciência operacional completa. Por isso, a entidade conta a ajuda de doações espontâneas por parte da solidariedade da população, então comprando a sacola você estará ajudando essa entidade que faz um trabalho sério para nossa comunidade. Vem fazer parte dessa campanha e faça sua Compra Sustentável, ajude mudar o planeta e a vida das pessoas que nele residem
          </p>

          <styles.IconsSocial>
            <img src={lar} alt="lar" />

            <p>Conheça mais sobre no nosso instagram</p>
            <span>@lardosvelhinosderioazul</span>

            <Button text="Ajude o lar dos velhinhos" green />

            <h2 className="section_title">E concorra a 02 iphones 14 por apenas R$ 10,00</h2>
          </styles.IconsSocial>
        </styles.IconsGroup>

        <styles.IconsImg>
          <img className="img_bag" src={img} alt="bag" />
        </styles.IconsImg>

        <styles.IconsGroup>
          <img src={ajude1} alt="lar dos velhinos" />

          <h2 className="section_title">Cta Rebouças - PR</h2>

          <p className="section_p">
            CTA é uma entidade sem ﬁns lucrativos, localizada na cidade de Rebouças interior do Paraná, com 38 anos de existência, a instituição atende crianças e adolescentes de 5 a 17 anos, como uma espécie de ensino complementar, segundo turno. São mais de 200 crianças atendidas todos os dias, onde recebem alimentação, atividades extracurriculares como música, dança, esporte, corte e costura, culinária, informática, estética, robótica e acima de tudo noções de cidadania, enfatizando o respeito pelo próximo e pelo meio ambiente, formando verdadeiros cidadãos, que saem mais preparados para enfrentar o mundo real. Com proﬁssionais extremamente dedicados a mudar a realidade dessas crianças, o CTA traz a essas crianças e adolescentes a oportunidade de socializar de forma que contribua para a formação do seu caráter. Infelizmente pela diﬁculdade ﬁnanceira da maioria dos pais, caso essas crianças ﬁcassem em casa no período do contraturno escolar, estariam sujeitas a situações de risco e vulnerabilidade. Comprando a sacola, você estará ajudando a contribuir para o futuro dessas crianças o projeto Compra Sustentável ajuda o planeta e as crianças que nele estão crescendo.
          </p>

          <styles.IconsSocial>
            <img src={cta} alt="lar" />

            <p>Conheça mais sobre no nosso instagram</p>
            <span>@ctareboucas</span>

            <Button text="Ajude o CTA" green />

            <h2 className="section_title">E concorra a 02 iphones 14 por apenas R$ 10,00</h2>

            <img src={iphone} alt="iphone" />
          </styles.IconsSocial>
        </styles.IconsGroup>

      </div>
    </styles.IconsSt>
  );
}
