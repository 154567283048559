import styled from 'styled-components';

export const ButtonSt = styled.div`
  width: 100%;
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  

  a{
    padding: 12px;
    font-size: 24px;
    font-weight: 900;
    letter-spacing: 0.7px;
    text-transform: uppercase;
    color: ${(props) => props.theme.textLigth};
    width: 100%;
    border-radius: 0.5rem;
    text-align: center;

    background: ${(props) => props.theme.orange};
    background: -moz-linear-gradient(90deg,  ${(props) => props.theme.red} 0%, ${(props) => props.theme.orange} 50%);
    background: -webkit-linear-gradient(90deg,  ${(props) => props.theme.red} 0%, ${(props) => props.theme.orange} 50%);
    background: linear-gradient(90deg,  ${(props) => props.theme.red} 0%, ${(props) => props.theme.orange} 50%);
  }

`;
