import React from 'react';
import { ThemeProvider } from 'styled-components';
import { GlobalStyles } from './GlobalStyles';
import { themeDefault } from './style/theme';
import Top from './layout/Top';
import Banner from './layout/Banner';
import Info from './layout/Info';
import Icons from './layout/Icons';
import Combo from './layout/Combo';
import Partnership from './layout/Partnership';
import Footer from './layout/Footer';

function App() {
  return (
    <ThemeProvider theme={themeDefault}>
      <GlobalStyles />

      <Top />
      <div className="center">
        <Banner />
        <Info />
      </div>
      <Icons />
      <Combo />
      <Partnership />

      <Footer />

    </ThemeProvider>
  );
}

export default App;
