import React from 'react';
import { PartnershipSt } from './styles';
import img from '../../assets/logo_campanha.png';

export default function Partnership() {
  return (
    <PartnershipSt>
      <div className="center">
        <img src={img} alt="logo" />
      </div>
    </PartnershipSt>
  );
}
