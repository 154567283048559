import styled from 'styled-components';

export const IconsSt = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const IconsTop = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${(props) => props.theme.greenDark};
  padding: 8px 0;

  h3{
    text-align: center;
    font-size: 16px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 600;
    color: ${(props) => props.theme.textLigth};
  }
  
`;

export const IconsGroup = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 20px;

  h2{
    margin-top: 20px;
  }

  img{
    width: 100%;
    max-width: 130px;
  }
`;

export const IconsSocial = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 15px;

  img{
    max-width: 300px;
    object-fit: cover;
    border-radius: 0.5rem;
  }

  p{
    font-size: 16px;
    margin-top: 5px;
    font-weight: 300;
    text-transform: uppercase;
  }

  span{
    font-size: 19px;
  }

  a{
    margin-top: 10px;
  }

  h2{
    color: ${(props) => props.theme.textLigth};
    font-size: 20px;
    margin-top: 15px;
  }
`;

export const IconsImg = styled.section`

  margin: 25px 0 15px 0;
  border-bottom: 2px solid ${(props) => props.theme.greenDark};
  border-top: 2px solid ${(props) => props.theme.greenDark};

  .img_bag{
    width: 100vw;
    max-width: 600px;
    margin-bottom: 30px;
    
    @media screen and (max-width: 800px){
      position: relative;
      left: calc(-5% - 10px);
      top: 20px; 
    }
  }
`;
