import React from 'react';
import PropTypes from 'prop-types';
import * as styles from './styles';

export default function Buy({
  unid, price, cupom, link,
}) {
  return (
    <styles.BuySt>
      <h2>
        Kit
        {' '}
        {unid}
        {' '}
        ecobags
        <br />
        por apenas
      </h2>

      <styles.BuyPrice>
        <h4>R$</h4>
        <h3>{price}</h3>
        <h4>,99</h4>
      </styles.BuyPrice>

      <styles.BuyText>
        <h2>Você ganha</h2>
        <h4>
          {cupom}
          {' '}
          cupons
        </h4>
        <p>Para concorrer a</p>
        <h2>02 iphones 14</h2>
      </styles.BuyText>

      <styles.BuyButton href={link} target="blank">
        Comprar
      </styles.BuyButton>

    </styles.BuySt>
  );
}

Buy.propTypes = {
  unid: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  cupom: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};
