import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-scroll';
import { ButtonSt } from './style';

export default function Button({ text }) {
  return (
    <ButtonSt>
      <Link to="combo" duration="0.5s">{text}</Link>
    </ButtonSt>
  );
}

Button.propTypes = {
  text: PropTypes.string.isRequired,
};
